<template>
    <div class="gradient-wrapper1">
    <canvas id="gradient-canvas" style="width:100vw;height:100vh"></canvas>
    </div>
  </template>
  
  <script>
  export default {
    name: 'GradientCanvas',
  };
  </script>
  
  <style scoped>
  body {
    margin: 0;
    padding: 0;
  }
  
  #gradient-canvas {
    --gradient-color-1: #6ec3f4;
    --gradient-color-2: #3a3aff;
    --gradient-color-3: #ff61ab;
    --gradient-color-4: #E63946;
  }
  </style>
  