<template>
  <NavBar />
  <div>
    <!-- Hero Section -->
    <section class="hero-section base-style index">
      <!-- Gradient Canvas as background -->
      <div class="platform-hero-container">
        <div class="hero-content full">
          <GradientCanvas class="gradient-canvas" />
          <div class="platform-container">
            <div class="text-content">
              <h4>Auto Machine Learning</h4>
              <h1>Automate Your Machine Learning Journey</h1>
              <p>
                Accelerate your ML projects with automatic model selection, tuning, and
                deployment—empowering you to build models efficiently without any coding.
              </p>
            </div>
            <div class="hero-image-container">
              <AutoMLVisual />
            </div>
          </div>
        </div>
        <!-- Static High-Tech Design Elements -->
        <div class="static-grid"></div>
        <div class="static-circuit-lines"></div>
      </div>
    </section>

    <!-- Card Section with Icons -->
    <section class="card-section variant3 dashed">
      <div class="platform-container">
        <header>
          <h4>AutoML</h4>
          <h2>Performance Stats</h2>
          <p>See how our platform outperforms competitors in speed and accuracy.</p>
        </header>
        <div class="card-content">
          <div class="card">
            <LightBulbIcon class="card-icon" />
            <h3>Effortless Model Selection</h3>
            <p>
              Leverage AutoML to automatically select the best models for your data,
              saving you time and improving accuracy.
            </p>
          </div>
          <div class="card">
            <CogIcon class="card-icon" />
            <h3>Hyperparameter Tuning</h3>
            <p>
              AutoML intelligently tunes model hyperparameters, ensuring you always
              achieve optimal results.
            </p>
          </div>
          <div class="card">
            <CloudArrowUpIcon class="card-icon" />
            <h3>Batch Predictions</h3>
            <p>
              Apply your models to large datasets with batch predictions, scaling your
              insights effortlessly.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Platform Content Section -->
    <section class="platform-content-section base-style">
      <div class="platform-container">
        <div class="platform-content">
          <div class="text-content">
            <h4>AutoML in Action</h4>
            <h2>Simplified, End-to-End Workflow</h2>
            <p>
              ML Clever's AutoML transforms the model-building journey with a fully
              automated, streamlined process. From data preparation to model training and
              evaluation, every step is seamlessly managed, allowing you to focus on
              insights rather than technical details. Achieve high-quality machine
              learning outcomes without the need for manual intervention.
            </p>
            <button class="submit-button-cls">Learn More</button>
          </div>
          <div class="image-container">
            <img src="/static/img/landing/automl_selection.png" alt="AutoML Workflow Overview" />
          </div>
        </div>
      </div>
    </section>

    <!-- Platform Content Section -->
    <section class="platform-content-section base-style">
      <div class="platform-container">
        <div class="platform-content">
          <div class="text-content">
            <h4>Optimize</h4>
            <h2>Adaptive, Intelligent AutoML</h2>
            <p>
              ML Clever's AutoML adapts dynamically to your data, fine-tuning
              hyperparameters and selecting optimal models based on complexity and scale.
              Every step is optimized to provide precise, high-performance results
              tailored to your needs.
            </p>
            <p>
              With efficient resource management and real-time monitoring, our AutoML
              engine balances speed with accuracy, making data-driven adjustments
              throughout the process. Experience a transparent, adaptive workflow that
              maximizes accuracy while minimizing token usage.
            </p>
            <button class="submit-button-cls">Learn More</button>
          </div>
          <div class="image-container">
            <img src="/static/img/platform/best_model.png" alt="AutoML Optimization Flow" />
          </div>
        </div>
      </div>
    </section>

    <!-- Slideshow 1 Section -->
    <section class="slide-show-content-section full-width">
      <div class="platform-container">
        <div class="slide-show-content">
          <header>
            <h4>Revolutionizing Automation</h4>
            <h2>Unleashing AI Potential</h2>
            <p>
              Discover how ML Clever transforms industries with automation, optimization,
              scalability, and innovation.
            </p>
          </header>

          <div class="slider-container">
            <div class="slider-content">
              <!-- Slideshow 1 Images with Fade Animation -->
              <transition name="fade" mode="out-in">
                <div class="slider-images" :key="slideshow1.currentSlide">
                  <a v-for="slide in slideshow1.slides" :key="slide.id" v-show="slideshow1.currentSlide === slide.id"
                    :href="slide.link || '#'" class="slide" :style="{
                  backgroundImage: `url(${slide.imgSrc})`,
                  '--slide-color': slide.color,
                  '--slide-color-before': slide.color_before,
                  '--slide-color-after': slide.color_after,
                  '--slide-color-fullwidth': slide.color_fullwidth,
                }">
                    <div class="overlay" :style="{ backgroundColor: slide.bgColor }"></div>
                    <div class="slide-link">
                      <h4>{{ slide.title }}</h4>
                      <p>{{ slide.linkText }}</p>
                    </div>
                    <component :is="slide.icon" class="slide-icon left" />
                    <ArrowUpRightIcon class="slide-icon" />
                  </a>
                </div>
              </transition>
            </div>

            <!-- Slideshow 1 Navigation Buttons -->
            <div class="slider-buttons">
              <button v-for="slide in slideshow1.slides" :key="slide.id"
                :class="{ active: slideshow1.currentSlide === slide.id }" @click="changeSlideShow1(slide.id)">
                {{ slide.buttonLabel }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Slideshow 2 Section -->
    <section class="slide-show-content-section">
      <div class="platform-container">
        <div class="slide-show-content">
          <header>
            <h4>AutoML</h4>
            <h2>Industry Applications of AutoML</h2>
            <p>
              Discover how ML Clever's AutoML capabilities bring value across diverse
              industries and contexts.
            </p>
          </header>
          <div class="slider-container">
            <div class="slider-content">
              <!-- Slideshow 2 Text Content with Slide Animation -->
              <transition :name="slideshow2Direction === 'right' ? 'slide-left' : 'slide-right'" mode="out-in">
                <div class="slider-column" :key="slideshow2.currentSlide">
                  <div v-if="slideshow2.currentSlide === 1" class="stat">
                    <h3>Transform Education Outcomes</h3>
                    <p>
                      See how our AutoML platform helps educators enhance student
                      performance through predictive analytics.
                    </p>
                  </div>
                  <div v-if="slideshow2.currentSlide === 2" class="stat">
                    <h3>Boost SMB Growth with Smart Decisions</h3>
                    <p>
                      Equip small businesses with data-driven insights to optimize
                      operations, resource management, and customer targeting.
                    </p>
                  </div>
                  <div v-if="slideshow2.currentSlide === 3" class="stat">
                    <h3>Healthcare Analytics for Better Patient Care</h3>
                    <p>
                      Empower healthcare providers with predictive analytics to improve
                      treatment outcomes and operational efficiency.
                    </p>
                  </div>
                  <div v-if="slideshow2.currentSlide === 4" class="stat">
                    <h3>Enterprise Optimization for Scalability</h3>
                    <p>
                      Leverage AutoML to enhance large-scale enterprise operations, from
                      predictive maintenance to strategic decision-making.
                    </p>
                  </div>
                </div>
              </transition>

              <!-- Slideshow 2 Images with Fade Animation -->
              <transition name="fade" mode="out-in">
                <div class="slider-images" :key="slideshow2.currentSlide">
                  <div v-show="slideshow2.currentSlide === 1" class="slide" :style="{
                  backgroundImage: `url(/static/img/webp/platform_image93.webp)`,
                }">
                    <div class="overlay"></div>
                    <div class="slide-link">
                      <a href="#">Learn how ML Clever is transforming education with predictive
                        insights</a>
                    </div>
                    <AcademicCapIcon class="slide-icon left" />
                    <ArrowUpRightIcon class="slide-icon" />
                  </div>
                  <div v-show="slideshow2.currentSlide === 2" class="slide" :style="{
                  backgroundImage: `url(/static/img/webp/platform_image47.webp)`,
                }">
                    <div class="overlay"></div>
                    <div class="slide-link">
                      <a href="#">Empower SMBs with data-driven insights to thrive in competitive
                        markets</a>
                    </div>
                    <StoreIcon class="slide-icon left" />
                    <ArrowUpRightIcon class="slide-icon" />
                  </div>
                  <div v-show="slideshow2.currentSlide === 3" class="slide" :style="{
                  backgroundImage: `url(/static/img/webp/platform_image8.webp)`,
                }">
                    <div class="overlay"></div>
                    <div class="slide-link">
                      <a href="#">Explore how ML Clever enhances patient care with predictive
                        analytics</a>
                    </div>
                    <HeartIcon class="slide-icon left" />
                    <ArrowUpRightIcon class="slide-icon" />
                  </div>
                  <div v-show="slideshow2.currentSlide === 4" class="slide" :style="{
                  backgroundImage: `url(/static/img/webp/platform_image42.webp)`,
                }">
                    <div class="overlay"></div>
                    <div class="slide-link">
                      <a href="#">See how enterprises scale with AutoML solutions tailored for
                        growth</a>
                    </div>
                    <BriefcaseIcon class="slide-icon left" />
                    <ArrowUpRightIcon class="slide-icon" />
                  </div>
                </div>
              </transition>
            </div>

            <!-- Slideshow 2 Navigation Buttons -->
            <div class="slider-buttons">
              <button :class="{ active: slideshow2.currentSlide === 1 }" @click="changeSlideShow2(1, 'left')">
                Education
              </button>
              <button :class="{ active: slideshow2.currentSlide === 2 }" @click="changeSlideShow2(2, 'right')">
                SMB
              </button>
              <button :class="{ active: slideshow2.currentSlide === 3 }" @click="changeSlideShow2(3, 'right')">
                Healthcare
              </button>
              <button :class="{ active: slideshow2.currentSlide === 4 }" @click="changeSlideShow2(4, 'right')">
                Enterprise
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Stats 2 -->
    <section class="stat-section base-style">
      <div class="platform-container">
        <header>
          <h4>AutoML</h4>
          <h2>Platform Performance Capabilities</h2>
          <p>
            Discover the power and flexibility of our platform’s AutoML, enhancing your
            machine learning workflow from start to deployment.
          </p>
        </header>
        <div class="stat-content">
          <div class="stat">
            <h3>Comprehensive Model Library</h3>
            <ul>
              <li>Includes 12+ models for both classification and regression tasks</li>
              <li>
                From Logistic Regression to Gradient Boosting, covering all complexity
                levels
              </li>
              <li>Advanced hyperparameter tuning for each model category</li>
            </ul>
          </div>
          <div class="stat">
            <h3>Seamless Model Training Progress</h3>
            <ul>
              <li>
                Automatic switching between basic, medium, and advanced configurations
              </li>
              <li>Real-time progress tracking for each stage of model development</li>
              <li>Fully guided training from data preparation to final evaluation</li>
            </ul>
          </div>
          <div class="stat">
            <h3>Rapid Iteration and Experimentation</h3>
            <ul>
              <li>Evaluate multiple models in parallel for rapid iteration</li>
              <li>
                Adaptive workflows to automatically switch models based on training
                results
              </li>
              <li>
                Optimized for high experimentation speeds to find the best model in less
                time
              </li>
            </ul>
          </div>
          <div class="stat">
            <h3>Scalable Architecture</h3>
            <ul>
              <li>Designed to handle a large number of models concurrently</li>
              <li>Automatic resource allocation for efficient model training</li>
              <li>Scales from small datasets to enterprise-level data requirements</li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <!-- Platform Content Section -->
    <section class="platform-content-section base-style">
      <div class="platform-container">
        <div class="platform-content">
          <div class="text-content">
            <h4>AutoML in Action</h4>
            <h2>Seamless Workflow from Start to Finish</h2>
            <p>
              With ML Clever's AutoML, every stage of the model-building process is
              automated to ensure ease and accuracy. From data loading to training, and
              from evaluating metrics to deployment, our streamlined process helps you
              achieve machine learning success without manual intervention.
            </p>
            <button class="submit-button-cls">Learn More</button>
          </div>
          <div class="image-container">
            <DataPreprocessingVisual />
          </div>
        </div>
      </div>
    </section>

    <!-- Platform Content Section -->
    <section class="platform-content-section base-style">
      <div class="platform-container">
        <div class="platform-content">
          <div class="text-content">
            <h4>AutoML Workflow</h4>
            <h2>Comprehensive and Streamlined Experience</h2>
            <p>
              Our AutoML workflow takes you step-by-step through the entire model training
              journey—from data preprocessing, automatic feature selection, to the best
              model identification and deployment. ML Clever empowers users to focus on
              the outcomes while handling the complexities of machine learning behind the
              scenes.
            </p>
            <button class="submit-button-cls">Learn More</button>
          </div>
          <div class="image-container">
            <img src="/static/img/platform/training1.png" alt="Comprehensive AutoML Dashboard" />
          </div>
        </div>
      </div>
    </section>

    <!-- Card Section with Images -->
    <section class="card-section with-image">
      <div class="platform-container">
        <header>
          <h4>Key Advantages</h4>
          <h2>Why Choose AutoML?</h2>
          <p>
            ML Clever’s AutoML empowers businesses to streamline their machine learning
            workflows with efficiency and precision.
          </p>
        </header>
        <div class="card-content">
          <div class="card">
            <img src="/static/img/webp/platform_image83.webp" alt="Automated Feature Selection" class="card-img" />
            <div class="card-text-container">
              <h3>Automated Feature Selection</h3>
              <p>
                Save time by letting AutoML automatically identify the most relevant
                features in your data for optimal model performance.
              </p>
              <p class="learn-more">Learn More</p>
            </div>
          </div>
          <div class="card">
            <img src="/static/img/webp/platform_image84.webp" alt="Hyperparameter Optimization" class="card-img" />
            <div class="card-text-container">
              <h3>Hyperparameter Optimization</h3>
              <p>
                Automatically fine-tune hyperparameters to achieve peak model accuracy,
                tailored to your dataset and goals.
              </p>
              <p class="learn-more">Learn More</p>
            </div>
          </div>
          <div class="card">
            <img src="/static/img/webp/platform_image85.webp" alt="Model Deployment Made Easy" class="card-img" />
            <div class="card-text-container">
              <h3>Seamless Model Deployment</h3>
              <p>
                Deploy models effortlessly in real-time or batch mode, ensuring quick
                integration into your business processes.
              </p>
              <p class="learn-more">Learn More</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Platform Content Section -->
    <section class="platform-content-section base-style">
      <div class="platform-container">
        <div class="platform-content">
          <div class="text-content">
            <h4>Empower Your Data</h4>
            <h2>No-Code AI for Every Ambition</h2>
            <p>
              Discover the easiest way to harness machine learning. Start with our entry
              plan to explore powerful features, and upgrade to unlock advanced tools
              tailored for teams, businesses, and enterprises. Build smarter, faster, and
              scale effortlessly with pricing options designed to grow with you.
            </p>
            <button class="submit-button-cls">Choose Your Plan</button>
          </div>
          <div class="image-container stats">
            <div class="stat">
              <h3>Plans That Fit Your Needs</h3>
              <ul>
                <li>Flexible entry plan to get started</li>
                <li>Pro and Enterprise tiers for scaling businesses</li>
                <li>Custom options for unique requirements</li>
              </ul>
            </div>
            <div class="stat">
              <h3>Seamless, Automated Workflows</h3>
              <ul>
                <li>Effortless data preprocessing, training, and deployment</li>
                <li>Customizable pipelines for tailored solutions</li>
                <li>Real-time predictions and insights at your fingertips</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, defineAsyncComponent } from "vue";
import NavBar from "@/components/basic/NavBar.vue";
import GradientCanvas from "@/components/platform/gradients/GradientCanvas1.vue";
const DataPreprocessingVisual = defineAsyncComponent(() =>
  import("@/components/platform/visuals/DataPreprocessingVisual")
);
const AutoMLVisual = defineAsyncComponent(() =>
  import("@/components/platform/visuals/AutoMLVisual")
);
import {
  HeartIcon,
  CogIcon,
  ArrowUpRightIcon,
  AcademicCapIcon,
  LightBulbIcon,
  CloudArrowUpIcon,
  StoreIcon,
  BriefcaseIcon,
  Cog6ToothIcon,
  BoltIcon,
  ArrowsPointingOutIcon,
  SparklesIcon,
} from "@heroicons/vue/24/outline";

const slideshow1 = ref({
  currentSlide: 1,
  slides: [
    {
      id: 1,
      imgSrc: "/static/img/webp/platform_image6.webp",
      title: "Effortless Model Automation",
      link: "#",
      linkText:
        "Transform the way you build machine learning models. ML Clever automates everything—from data preprocessing to deployment—ensuring unmatched speed and accuracy in your workflows.",
      buttonLabel: "Industry Automation",
      icon: Cog6ToothIcon, // Heroicons Cog6ToothIcon for automation and machinery
      color: "#1E293B", // Dark blue-gray to represent professionalism and reliability
      color_before: "#1E293B00", // 0% opacity
      color_after: "#1E293B80", // 50% opacity
      color_fullwidth: "#1E293B40", // 25% opacity
    },
    {
      id: 2,
      imgSrc: "/static/img/webp/platform_image10.webp",
      title: "Hyper-Optimized Performance",
      link: "#",
      linkText:
        "Achieve peak model performance with cutting-edge hyperparameter tuning and real-time optimization. ML Clever ensures your models are always at their best, no matter the complexity.",
      buttonLabel: "Performance Tuning",
      icon: BoltIcon, // Heroicons BoltIcon for performance and speed
      color: "#3498DB", // Electric blue for futuristic and performance-driven vibe
      color_before: "#3498DB00", // 0% opacity
      color_after: "#3498DB80", // 50% opacity
      color_fullwidth: "#3498DB40", // 25% opacity
    },
    {
      id: 3,
      imgSrc: "/static/img/webp/platform_image12.webp",
      title: "Scalable for Every Need",
      link: "#",
      linkText:
        "From startups to global enterprises, ML Clever’s AutoML platform adapts to projects of any size, delivering scalability and powerful insights for your unique challenges.",
      buttonLabel: "Scalability",
      icon: ArrowsPointingOutIcon, // Heroicons ArrowsPointingOutIcon for growth and scalability
      color: "#E67E22", // Warm orange for scalability and growth
      color_before: "#E67E2200", // 0% opacity
      color_after: "#E67E2280", // 50% opacity
      color_fullwidth: "#E67E2240", // 25% opacity
    },
    {
      id: 4,
      imgSrc: "/static/img/webp/platform_image4.webp",
      title: "Empowering Innovation Through Automation",
      link: "#",
      linkText:
        "Focus on your vision while we handle the complexity. With ML Clever, automation becomes your secret weapon for driving innovation and efficiency at every level.",
      buttonLabel: "Innovation",
      icon: SparklesIcon, // Heroicons SparklesIcon for inspiration and innovation
      color: "#8E44AD", // Purple for innovation and creativity
      color_before: "#8E44AD00", // 0% opacity
      color_after: "#8E44AD80", // 50% opacity
      color_fullwidth: "#8E44AD40", // 25% opacity
    },
  ],
  intervalId: null,
});

// Slideshow 2 Data
const slideshow2 = ref({
  currentSlide: 1,
  intervalId: null,
});

const slideshow2Direction = ref("right");

const startAutoSlideShow1 = () => {
  clearInterval(slideshow1.value.intervalId);
  slideshow1.value.intervalId = setInterval(() => {
    slideshow1.value.currentSlide =
      slideshow1.value.currentSlide < slideshow1.value.slides.length
        ? slideshow1.value.currentSlide + 1
        : 1;
  }, 10000);
};

const changeSlideShow1 = (slideId) => {
  slideshow1.value.currentSlide = slideId;
  startAutoSlideShow1();
};

const startAutoSlideShow2 = () => {
  clearInterval(slideshow2.value.intervalId);
  slideshow2.value.intervalId = setInterval(() => {
    slideshow2.value.currentSlide =
      slideshow2.value.currentSlide < 4 ? slideshow2.value.currentSlide + 1 : 1;
  }, 10000);
};

const changeSlideShow2 = (slideId, direction) => {
  slideshow2.value.currentSlide = slideId;
  slideshow2Direction.value = direction;
  startAutoSlideShow2();
};

// Preload Images
const preloadImages = () => {
  slideshow1.value.slides.forEach((slide) => {
    const img = new Image();
    img.src = slide.imgSrc;
  });
};

onMounted(() => {
  preloadImages();
  startAutoSlideShow1();
  startAutoSlideShow2();
});
onBeforeUnmount(() => {
  clearInterval(slideshow1.value.intervalId);
  clearInterval(slideshow2.value.intervalId);
});
</script>
